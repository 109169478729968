import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import userReducer from "./user/user.reducer";
/* import propertiesReducer from "./properties/properties.reducer";
import draftsReducer from "./drafts/drafts.reducer"; */

const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    /*"properties",
    "drafts",*/
    /*"cart"*/
  ], //not adding user bcz firestore handles it for us
};

const rootReducer = combineReducers({
  user: userReducer,
  /* properties: propertiesReducer,
  drafts: draftsReducer, */
});

export default persistReducer(persistConfig, rootReducer);
