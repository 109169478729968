import React, { Fragment, useEffect, useState, lazy, Suspense } from "react";
import Container from "@mui/material/Container";

import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { selectCurrentUser } from "./redux/user/user.selectors";
import { checkUserSession } from "./redux/user/user.actions";

import Spinner from "./components/spinner/spinner";

const HomePage = lazy(() => import("./pages/homepage/homepage"));
const LoginPage = lazy(() => import("./pages/login/login"));

export default function App() {
  const [isSignedin, setIsSignedIn] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(checkUserSession());
  }, [dispatch]);

  const currentUserObject = useSelector(selectCurrentUser);

  useEffect(() => {
    if (!!!currentUserObject) {
      const timer = setTimeout(function () {
        history.push("/login");
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [currentUserObject]);

  return (
    <Fragment>
      <Switch>
        <Suspense fallback={<Spinner />}>
          <Route
            exact
            path="/"
            render={() =>
              !!currentUserObject ? <HomePage /> : <Redirect to="/login" />
            }
          />
          <Route
            exact
            path="/login"
            render={() =>
              !!currentUserObject ? <Redirect to="/" /> : <LoginPage />
            }
          />
          <Route path="*">
            <HomePage />
          </Route>
        </Suspense>
      </Switch>
    </Fragment>
  );
}
